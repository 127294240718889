import React, { useEffect, useState } from 'react';
import { getHash } from '~/utils';
import { globalStore } from '~/store';
import JsonView from 'react18-json-view';
import 'react18-json-view/src/style.css';
interface ServerHintsProps {
  headers: string;
}

const ServerHints: React.FC<ServerHintsProps> = ({ headers }) => {
  const [headerData, setHeaderData] = useState<any>(null);

  useEffect(() => {
    const fetchHeaders = async () => {
      const headersObject = JSON.parse(headers);
      const sanitizedHeaders = Object.fromEntries(Object.entries(headersObject).map(([key, value]) => [key, (value as string).replace(/["\\/]/g, '')]));

      const hash = await getHash(sanitizedHeaders);
      const data = { hash, data: sanitizedHeaders };

      setHeaderData(data);
      globalStore.set({ ...globalStore.get(), server_hints: data });
    };

    fetchHeaders();
  }, []);

  return (
    <div>
      <h2 className="text-3xl font-medium mb-5 flex items-center gap-2">
        Server Hints <span className="text-sm border px-1">{headerData?.hash.slice(0, 12)}</span>
      </h2>
      <div className="overflow-auto border p-2 rounded-lg border-slate-200 h-auto max-h-[500px]">
        <JsonView src={headerData} />
      </div>
    </div>
  );
};

export default ServerHints;
